@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700;800&display=swap');

*::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
	background-color: #FAFAFA;
}

*::-webkit-scrollbar {
	width: 8px;
	height: 5px;
	background-color: #FAFAFA;
}

*::-webkit-scrollbar-thumb {
	border-radius: 2px;
	-webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
	background-color: #254675;
}

div::-webkit-scrollbar {
	width: 5px !important
}

div::-webkit-scrollbar-thumb {
	background-color: #777 !important
}

body {
	background-color: rgb(249, 250, 251) !important;
}